import FlexBox from "components/layout/FlexBox"
import {useAppContext} from "api/app/AppContext"
import { useTheme} from "hooks/useTheme"

const Footer = () => {
  const {appState} = useAppContext()
  const {tenantConfig} = appState || {}
  const {contactDetails, privacyPolicyUrl} = tenantConfig || {}
  const {phoneNumber, email} = contactDetails || {}
  const theme = useTheme()

  return (
    <FlexBox style={{backgroundColor: "#7c7d7d", color: "#FFF", width: "100%"}} centered column>
      {
        contactDetails &&
        <FlexBox column centered tPadding>
          <FlexBox>
            Need to contact support?
          </FlexBox>
          <FlexBox>
            <FlexBox padding>
              <FlexBox>{email}</FlexBox>
            </FlexBox>
            <FlexBox padding>
              <FlexBox>{phoneNumber}</FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      }
      {
        privacyPolicyUrl &&
        <FlexBox flexGrow centered margin>
          <a style={theme.linkStyle} target="_blank" rel="noreferrer" href={privacyPolicyUrl}>Privacy Policy</a>
        </FlexBox>
      }
    </FlexBox>
  )
}

export default Footer
