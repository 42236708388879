import useOnmount from "hooks/useOnMount"
import {get} from "lib/get"
import queryString from "query-string"
import {findTenantByDomain, findTenantByTenantCode} from "tenantConfig"
import {useAppContext} from "api/app/AppContext"

const TOKEN_KEY = "sh-token"

function useTokenParser() {
  const {appState, onTokenUpdated, onNoTokenFound, onTenantConfigUpdated} = useAppContext()

  useOnmount(() => {
    const tStr = "/t/"
    const tokenStr = "/token/"
    const tFound = window.location.pathname.indexOf(tStr)
    const tokenFound = window.location.pathname.indexOf(tokenStr)

    let pathToken = undefined
    if (tFound !== -1) {
      pathToken = window.location.pathname.substring(tFound + tStr.length)
    } else if (tokenFound !== -1) {
      pathToken = window.location.pathname.substring(tokenFound + tokenStr.length)
    }

    const search = get(["location", "search"], window)
    const {t: tokenShort, token: tokenLong} = queryString.parse(search) || {}
    const token = tokenShort || tokenLong || pathToken
    let tenantConfig= findTenantByDomain(window.location.hostname)

    // use sprinthive config as default
    if (!tenantConfig) {
      tenantConfig = findTenantByTenantCode("sh")
    }

    if (tenantConfig) {
      onTenantConfigUpdated(tenantConfig)
    }

    let cleanToken = token
    if (token?.endsWith("/")) {
      cleanToken = token.substring(0, token.length -1)
    }

    if (cleanToken?.trim().length > 0) {
      // we have found a token in the url
      onTokenUpdated(cleanToken)

      if (appState?.deviceInfo?.features?.localStorage === true) {
        const storage = window.localStorage
        storage.setItem(TOKEN_KEY, cleanToken)
      }
    } else {
      // we did not find a token in the url see if we have one in local storage
      if (appState?.deviceInfo?.features?.localStorage === true) {
        const storage = window.localStorage
        const storedToken = storage.getItem(TOKEN_KEY)
        if (storedToken?.trim().length > 0) {
          onTokenUpdated(storedToken)
        }
      } else if (tFound !== -1 && tokenFound !== -1) {
        // we want to trigger "No token present" only if the user is at step 1
        onNoTokenFound()
      }
    }
  })
}

export default useTokenParser
