import {from, of} from "rxjs"
import axios from "axios"
import {mergeMap} from "rxjs/operators"
import {uploadServiceEndpoint} from "AppConfig"

const IdentityApi = {
  captureSecureSelfie: ({apiKey, payload}) => {
    const {token, encryptedPhoto} = payload || {}
    const data = new FormData();
    data.append("file", encryptedPhoto);

    return from(
      axios.post(`${uploadServiceEndpoint}/uploadRequest/secureCaptureSelfie`,
        data,
        {params: {token}, headers: {apikey: apiKey}}
      )
    ).pipe(mergeMap(({data}) => of(data)))
  },
}

export default IdentityApi
