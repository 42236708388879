import axios from "axios"
import {mergeMap} from "rxjs/operators"
import {of, from} from "rxjs"
import {uploadServiceEndpoint} from "AppConfig"

const onUploadProgress = progressEvent => {
  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  console.log("Upload Progress", percentCompleted)
}

const IncomeApi = {
  uploadBankStatement: ({payload, apiKey}) => {
    const {token, formData} = payload
    return from(axios.post(`${uploadServiceEndpoint}/uploadRequest/bankStatement`,
      formData,
      {onUploadProgress, params: {token}, headers: {apikey: apiKey}}
    )).pipe(mergeMap(({data}) => of({data})))
  },
  uploadPayslip: ({payload, apiKey}) => {
    const {token, formData} = payload
    return from(axios.post(`${uploadServiceEndpoint}/uploadRequest/payslip`,
      formData,
      {onUploadProgress, params: {token}, headers: {apikey: apiKey}}
    )).pipe(mergeMap(({data}) => of({data})))
  },
}

export default IncomeApi
