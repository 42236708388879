import React, {useState} from "react"
import {useAppContext} from "api/app/AppContext"
import useOnmount from "hooks/useOnMount"
import FlexBox from "components/layout/FlexBox"
import Button from "components/Button"
import {Routes} from "MobileApp"
import tipImage from "images/light.png"
import {useTheme} from "hooks/useTheme"
import * as Sentry from "@sentry/react"

const defaultConfigProfile = {
  title: "Application",
  message: "To assist you with your application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
  buttonText: "VERIFY USING CAMERA"
}

export function Identity ({history}) {
  const {appState, onLandingPageMounted, onNoConfigurationProfileFound} = useAppContext()
  const {token, tokenState, tenantConfig} = appState || {}
  const [config, updateConfigurationProfile] = useState(defaultConfigProfile)
  const configProfileId = tokenState?.configProfileId || tokenState?.configurationProfile?.configProfileId

  Sentry.setTag("Token", tokenState.token)
  Sentry.setTag("UploadRequestId", tokenState.uploadRequestId)

  useOnmount(() => {
    onLandingPageMounted(token)

    const config = tenantConfig.messages[configProfileId]
    if (config) {
      updateConfigurationProfile(config)
    } else {
      onNoConfigurationProfileFound({message: `Could not find configurationProfile for "${configProfileId}"`
      })
    }
  })

  const {customer, status} = tokenState || {}
  const customerName = customer?.name


  if (status === "COMPLETED") {
    return (
      <FlexBox flexGrow centered column margin style={{textAlign: "center"}}>
        The verification request has already been completed,
        <br/>
        no further action is required.
      </FlexBox>
    )
  } else if (status === "IN_PROGRESS") {
    return (
      <FlexBox flexGrow centered column marginBottom>
        <ContextMessage customerName={customerName} config={config}/>
        <FlexBox marginTop marginBottom>
          <Button
            onClick={() => {
              history.replace(Routes.identityStart)
            }}
          >
            {config.buttonText}
          </Button>
        </FlexBox>
        <GuidelinesMessage/>
      </FlexBox>
    )
  }
}

const ContextMessage = ({customerName, config}) => {
  const theme = useTheme()
  return (
    <FlexBox column centered padding>
      <FlexBox centered column
               style={{
                 maxWidth: 300,
                 marginLeft: 10,
                 marginRight: 10
               }}
      >
        <FlexBox centered style={theme.h2}>{config.title}</FlexBox>
        <FlexBox marginTop centered style={{fontSize: 18, fontWeight: 800}}>
          {customerName ? `Hi ${customerName},` : "Welcome,"}
        </FlexBox>
        <div dangerouslySetInnerHTML={{__html: config.message}}/>
        <p>
          TIP: It's not a profile photo, so you don't need to look your best.
        </p>
        <p>
          We will however need to access your phone's camera.
        </p>
      </FlexBox>
    </FlexBox>
  )
}

const GuidelinesMessage = () => (
  <FlexBox centered tPadding marginTop>
    <div>
      <img src={tipImage} width={40} style={{marginLeft: 10}} alt=""/>
    </div>
    <div style={{fontSize: 16, marginLeft: 10, marginRight: 10}}>
      <span style={{fontWeight: 600, fontSize: 17}}>
        Please follow these guidelines:
      </span>
      <ul style={{paddingLeft: 20}}>
        <li>Maintain a neutral expression and try not to smile</li>
        <li>Remove your glasses (spectacles and sunglasses)</li>
        <li>Ensure that there is sufficient light</li>
        <li>There are no shadows</li>
        <li>
          Ensure that there are no photos/portraits or other people in the
          background
        </li>
        <li>Hold the camera steady</li>
      </ul>
    </div>
  </FlexBox>
)