import React, {useEffect, useState} from "react";
import {interval, animationFrameScheduler} from "rxjs";
import {map} from "rxjs/operators";
import {takeUntil, repeat} from "rxjs/operators";
import {theme} from "theme/theme";

const Donut = ({count, percentComplete}) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
      <circle className="donut-hole"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill={theme.backgroundColor1}/>
      <circle className="donut-ring"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              stroke={theme.borderColor1}
              strokeWidth="3"/>

      <circle className="donut-segment"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              stroke={theme.backgroundColor5}
              strokeWidth="3"
              strokeDasharray={`${percentComplete} ${(100 - percentComplete)}`}
              strokeDashoffset="25"/>

      <text className="text"
            textAnchor="middle"
            fontSize={12}
            fill={theme.fontColor4}
            fontWeight={600}
            x="50%"
            y="60%">
        {count}
      </text>
    </svg>
  )
};

const useTimeCounter = (onResetCounter$) => {
  const [startTime, resetStartTime] = useState(Date.now());
  const [percentage, setPercentage] = useState(0);
  const [counter, setCounter] = useState(0)
  useEffect(() => {
    const refreshRate = 50;
    const duration = 1000;
    const totalIntervals = duration / refreshRate;
    const timer$ = interval(refreshRate, animationFrameScheduler).pipe(
      map(counter => counter + 1),
      map(counter => counter / totalIntervals * 100),
      takeUntil(interval(duration + refreshRate)),
      repeat()
    );

    const subscriptions = [];
    subscriptions.push(timer$
      .subscribe(percentage => {
        setPercentage(percentage);
        percentage === 100 && setCounter(Math.floor((Date.now() - startTime) / 1000));
      }));

    if (onResetCounter$) {
      subscriptions.push(onResetCounter$.subscribe(() => {
        resetStartTime(Date.now())
      }));
    }

    return () => {
      subscriptions.forEach(s => s.unsubscribe());
    };
  }, [onResetCounter$, startTime]);
  return [percentage, counter];
};

function LoadingCounter({onResetCounter$, useCounter}) {
  const [percentage, counter] = useTimeCounter(onResetCounter$);
  return <Donut count={useCounter === true ? counter : ""} percentComplete={percentage}/>

}

export default LoadingCounter
