import React from "react"
import {useAppContext} from "api/app/AppContext"
import useTokenParser from "TokenParser"
import DisplayCouldNotLoadMessage from "pages/DisplayCouldNotLoadMessage"
import MobileApp from "MobileApp"
import Loading from "components/Loading"
import FlexBox from "components/layout/FlexBox"

function Bootstrapper () {
  const {appState} = useAppContext()
  useTokenParser()

  const {noTokenFound, exchangeTokenFailed, token, tenantConfig, exchangeTokenLoading} = appState

  if (noTokenFound || exchangeTokenFailed || token === undefined || tenantConfig === undefined) {
    return <DisplayCouldNotLoadMessage />
  } else if (exchangeTokenLoading) {
    return (
      <FlexBox flexGrow centered>
        <Loading loadingMessage="Loading" withCountdown={false}/>
      </FlexBox>
    )
  } else {
    return <MobileApp />
  }
}

export default Bootstrapper