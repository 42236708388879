import {findTenantByTenantCode} from "tenantConfig"

export const theme = {

  debit: "#DEA99E",
  credit: "#24999b",
  success: "#5f9f63",
  warning: "#F8B71D",
  alert: "#C13426",
  borderColor1: "#62666B",
  borderColor2: "#000000",
  fontColor1: "#4a505d",
  fontColor2: "#FCFCFC",
  fontColor3: "#F8B71D",
  fontColor4: "#8F969C",
  fontColor5: "#7B7D7D",
  fontColor6: "#a8b0b7",
  linkColor: "#24999B",
  linkColor2: "#7096B5",
  linkColor3: "#57BFF7",
  backgroundColor1: "#FFF", // image button disabled
  backgroundColor2: "#7096B5", // backgroundTop
  backgroundColor3: "#4a505d", // footer
  backgroundColor4: "#CACFD2",
  backgroundColor5: "#24999B",
  backgroundColor6: "#F2F3F4",
  backgroundColor7: "#f6f6f6",
}

export const themeStyle = {
  sectionTitle: {
    color: theme.fontColor1,
    fontSize: 40,
    fontWeight: 300,
  }
}

export const defaultPallet = {
  colour1: "#000",
  colour2: "#FFF",
  colour3: "#17BC27"
}

export const getTheme = tenantCode => {
  const config = findTenantByTenantCode(tenantCode)
  return config !== undefined ? config.pallet : defaultPallet
}

export function generateTheme(tenantCode) {
  const config = findTenantByTenantCode(tenantCode)
  const pallet = config?.pallet || defaultPallet
  return {
    logo: config?.logo,
    buttonStyle: {
      cursor: "pointer",
      borderRadius: 8,
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      borderStyle: 'solid',
      borderWidth: 1,
      color: pallet.colour2,
      backgroundColor: pallet.colour1,
      userSelect: "none",
      borderColor: pallet.colour1
    },
    dotStyle: {
      base: {
        width: 18,
        height: 18,
        borderRadius: 50,
        backgroundColor: pallet.colour2,
        border: `solid 1px ${pallet.colour1}`,
        marginRight: 5
      },
      selected: {
        backgroundColor: pallet.colour1
      }
    },
    uploadFilesStyle: {
      hideFileInput: {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1
      },
      button: {
        display: "grid",
        gridGap: 10,
        alignItems: "center",
        gridTemplateColumns: "auto auto",
        color: pallet.colour2,
        fontWeight: 600,
        minWidth: 200,
        minHeight: 40,
        cursor: "pointer",
        padding: 10,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: pallet.colour1,
        backgroundColor: pallet.colour1,
        borderRadius: 10,
      },
      icon: {
        justifySelf: "end",
      },
      text: {
        justifySelf: "start",
      }
    },
    numberedDot: {
      base: {
        width: 25,
        height: 25,
        borderRadius: 50,
        backgroundColor: "#b7b6b6",
        color: pallet.colour2,
        border: "solid 2px #000",
        marginRight: 5
      },
      selected: {
        border: `solid 2px ${pallet.colour1}`,
        backgroundColor: pallet.colour1,
      },
      complete: {
        color: pallet.colour3,
        border: `solid 2px ${pallet.colour3}`,
      }
    },
    roundButton: {
      base: {
        minWidth: 25,
        minHeight: 25,
        borderRadius: 50,
        backgroundColor: pallet.colour2,
        border: `solid 1px ${pallet.colour1}`,
        marginRight: 5,
        cursor: "pointer"
      },
      inner: {
        width: 15,
        height: 15,
        borderRadius: 50,
        backgroundColor: pallet.colour1
      }
    },
    linkStyle: {
      color: "#fff",
      textDecoration: "none",
      fontSize: 15,
      fontWeight: "normal"
    },
    // Replaces h1 tags
    title: {
      fontSize: 40,
      fontWeight: 300,
      color: "#4a505d",
      margin: 0
    },
    h2: {
      display: "block",
      color: "#4a505d",
      fontSize: 24,
      margin: 0,
      fontWeight: 700
    },
    h3: {
      display: "block",
      color: "#4a505d",
      fontSize: 18.72,
      margin: 0,
      fontWeight: 700
    }
  }
}
