import {from, of} from "rxjs"
import axios from "axios"
import {mergeMap} from "rxjs/operators"

import {uploadServiceEndpoint} from "AppConfig"

const AppApi = {
  exchangeToken: ({payload, apiKey}) => {
    return from(
      axios.post(
        `${uploadServiceEndpoint}/uploadRequest/validate`,
        { deviceDateTime: new Date() },
        {params: payload, headers: {apikey: apiKey, "Content-Type": "application/json"}}
      )
    ).pipe(mergeMap(({data}) => of({data})))
  },
  blockedCamera: ({payload, apiKey}) => {
    return from(
        axios.post(
            `${uploadServiceEndpoint}/uploadRequest/blockedCamera`,
            null,
            {params: payload, headers: {apikey: apiKey}}
        )
    ).pipe(mergeMap(({data}) => of({data})))
  },
  logUserJourneyEvent: ({payload, apiKey}) => {
    return from(
      axios.post(
        `${window.location.origin}${process.env.PUBLIC_URL}/api/metric/capture`,
        payload,
        {headers: {apikey: apiKey, "Content-Type": "application/json"}}
      )
    ).pipe(mergeMap(({data}) => of({data})))
  }
}

export default AppApi