import React from "react"
import FlexBox from "components/layout/FlexBox"
import LoadingCounter from "components/LoadingCounter"
import {theme} from "theme/theme"

const Style = {
  message1: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 10,
    color: theme.fontColor4,
  },
  message2: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: 10,
    color: theme.fontColor4,
  }
}

const Loading = ({loadingMessage, withCounter}) => {
  return (
    <FlexBox column centered flexGrow>
      <FlexBox style={{maxWidth: 200}}>
        <LoadingCounter useCounter={withCounter}/>
      </FlexBox>
      <FlexBox style={Style.message1}>{loadingMessage}</FlexBox>
      <FlexBox style={Style.message2}>This may take a few seconds...</FlexBox>
    </FlexBox>
  )
}

export default Loading
