import {useAppContext} from "api/app/AppContext"
import {useEffect} from "react"

/**
 * A non-visual component which is used to update the fav.ico
 */
function FavIconUpdater() {
  const {appState} = useAppContext()
  const {tenantConfig} = appState || {}
  const favIconSrc = tenantConfig?.favIcon

  useEffect(() => {
    const icon = document.getElementById("favIcon")
    if (favIconSrc !== undefined && icon !== undefined) {
      icon.href = favIconSrc
    }
  }, [favIconSrc]);

  return null
}

export default FavIconUpdater