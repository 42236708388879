import {of} from "rxjs"
import {catchError, filter, mergeMap, switchMap} from "rxjs/operators"
import incomeApi from "./IncomeApi"
import {actionFactory, actionTypes} from "./IncomeReducer"

const IncomeEpics = {
  uploadBankStatement: (epic$, {apiKey}) => {
    return epic$.pipe(filter(({action}) => action.type === actionTypes.uploadBankStatement)).pipe(
      switchMap(({action: {payload}}) =>
        incomeApi.uploadBankStatement({payload, apiKey}).pipe(
          mergeMap(() => {
            return of(actionFactory.uploadBankStatementCompleted())
          }),
          catchError((error) => {
            return of(actionFactory.uploadBankStatementFailed({error, payload}))
          }),
        )
      )
    )
  },
  uploadPayslip: (epic$, {apiKey}) => {
    return epic$.pipe(filter(({action}) => action.type === actionTypes.uploadPayslip)).pipe(
      switchMap(({action: {payload}}) =>
        incomeApi.uploadPayslip({payload, apiKey}).pipe(
          mergeMap(() => {
            return of(actionFactory.uploadPayslipCompleted())
          }),
          catchError((error) => {
            return of(actionFactory.uploadPayslipFailed({error, payload}))
          }),
        )
      )
    )
  }
}

// convert object into an array of functions
export default Object.keys(IncomeEpics).map(k => IncomeEpics[k])
