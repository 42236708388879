import {of} from "rxjs"
import {catchError, filter, mergeMap, switchMap} from "rxjs/operators"
import {actionFactory, actionTypes} from "api/identity/IdentityReducer"
import identityApi from "api/identity/IdentityApi"

const IdentityEpics = {
  uploadSecureCaptureSelfie: (epic$, {apiKey}) => {
    return epic$.pipe(
      filter(({action}) => action.type === actionTypes.uploadSecureCaptureSelfie),
      switchMap(({action: {payload}}) =>
        identityApi.captureSecureSelfie({payload, apiKey})
          .pipe(
            mergeMap(() => {
              return of(actionFactory.uploadSecureCaptureSelfieCompleted({}))
            }),
            catchError((error) => {
              return of(actionFactory.uploadSecureCaptureSelfieFailed(error))
            }),
          )
      )
    )
  },
}

// convert object into an array of functions
export default Object.keys(IdentityEpics).map(k => IdentityEpics[k])
