import React from "react"
import {useAppContext} from "api/app/AppContext"
import useOnmount from "hooks/useOnMount"
import {Income} from "pages/mobile/Income";
import {Identity} from "pages/mobile/Identity";

function Landing(props) {
  const {appState, onLandingPageMounted} = useAppContext()
  const {token, tokenState} = appState || {}
  useOnmount(() => onLandingPageMounted(token))

  const {incomeVerification} = tokenState || {}
  if (incomeVerification) {
    return <Income {...props}/>
  } else {
    return <Identity {...props}/>
  }
}

export default Landing