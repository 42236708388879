import React from "react"
import FlexBox from "components/layout/FlexBox"
import {useTheme} from "hooks/useTheme"

function DisplayCouldNotLoadMessage() {
  const theme = useTheme()
  return (
    <FlexBox style={{minHeight: "90vh", lineHeight: 1.2, textAlign: "center"}} centered>
      <FlexBox style={theme.title}>Sorry, the website could not be loaded at this time</FlexBox>
    </FlexBox>
  )
}

export default DisplayCouldNotLoadMessage
