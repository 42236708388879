import {useEffect, useState} from "react"
import {useAppContext} from "api/app/AppContext"
import {generateTheme} from "theme/theme"

export function useTheme() {
  const {appState} = useAppContext()
  const tenantCode = appState?.tenantConfig?.tenantCode
  const [theme, setTheme] = useState(generateTheme())

  useEffect(() => {
    setTheme(generateTheme(tenantCode))
  }, [tenantCode, setTheme])

  return theme
}
