import React, {Suspense, lazy} from "react"
import {Redirect, Route} from "react-router-dom"
import {MobileLayout} from "components/layout/MobileLayout"
import Landing from "pages/mobile/Landing"
import Footer from "components/layout/Footer"
import FavIconUpdater from "pages/FavIconUpdater"
import {useAppContext} from "api/app/AppContext"
import Loading from "components/Loading"

//Lazy Loaded Imports - Start
//Identity
const _IdentityCaptureEncryptedSelfie = lazy(() => import("pages/mobile/IdentityCaptureEncryptedSelfie"))
const _IdentityCaptureEncryptedSelfieComplete = lazy(() => import("pages/mobile/IdentityCaptureEncryptedSelfieComplete"))
const _IdentityCaptureEncryptedSelfieFailed = lazy(() => import("pages/mobile/IdentityCaptureEncryptedSelfieFailed"))
const _IdentityCaptureEncryptedSelfieTimeout = lazy(() => import("pages/mobile/IdentityCaptureEncryptedSelfieTimeout"))
const _IdentityCaptureEncryptedSelfiePermissions = lazy(() => import("pages/mobile/IdentityCaptureEncryptedSelfiePermissions"))
const _IdentityUnexpectedError = lazy(() => import("pages/mobile/IdentityUnexpectedError"))

//Income
const _IncomeBankStatementUpload = lazy(() => import("pages/mobile/IncomeBankStatementUpload"))
const _IncomePayslipsUpload = lazy(() => import("pages/mobile/IncomePayslipsUpload"))
const _IncomeBankStatementOrPayslip = lazy(() => import("pages/mobile/IncomeBankStatementOrPayslip"))
const _IncomeComplete = lazy(() => import("pages/mobile/IncomeComplete"))

// Lazy Loaded Imports - End

export const Routes = {
  home: "/",
  identityStart: "/start",
  identityFailed: "/identity/failed",
  identityPermissions: "/identity/permissions",
  identityComplete: "/identity/complete",
  identityTimeout: "/identity/timeout",
  identityError: "/identity/error",
  incomeStart: "/income/start",
  incomeBankStatementUpload: "/income/bankStatements/upload",
  incomePayslipUpload: "/income/payslips/upload",
  incomeComplete: "/income/complete"
}

// Route back to / to cater for /t/<someToken> and /token/<someToken>
function BackToRoute() {
  const {appState} = useAppContext()
  const {exchangeTokenLoading, tokenState} = appState
  if (exchangeTokenLoading === false && tokenState !== undefined) {
    return <Redirect to={Routes.home}/>
  } else {
    return null
  }
}

function MobileApp() {
  return (
    <Suspense fallback={<Loading loadingMessage="" withCountdown={false} />}>
    <MobileLayout footer={<Footer/>}>
      <Route path={"/t/:token"} component={BackToRoute}/>
      <Route path={"/token/:token"} component={BackToRoute}/>
      <Route path={Routes.home} component={FavIconUpdater}/>
      <Route path={Routes.home} exact component={Landing}/>
      <Route path={Routes.identityStart} component={_IdentityCaptureEncryptedSelfie}/>
      <Route path={Routes.identityComplete} component={_IdentityCaptureEncryptedSelfieComplete}/>
      <Route path={Routes.identityFailed} component={_IdentityCaptureEncryptedSelfieFailed}/>
      <Route path={Routes.identityTimeout} component={_IdentityCaptureEncryptedSelfieTimeout}/>
      <Route path={Routes.identityPermissions} component={_IdentityCaptureEncryptedSelfiePermissions}/>
      <Route path={Routes.identityError} component={_IdentityUnexpectedError} />


        <Route path={Routes.incomeStart} component={_IncomeBankStatementOrPayslip}/>
        <Route path={Routes.incomeBankStatementUpload} component={_IncomeBankStatementUpload}/>
        <Route path={Routes.incomePayslipUpload} component={_IncomePayslipsUpload}/>
        <Route path={Routes.incomeComplete} component={_IncomeComplete}/>
      </MobileLayout>
    </Suspense>
  )
}

export default MobileApp
