import FlexBox from "components/layout/FlexBox"
import Radium from "radium"
import {useTheme} from "hooks/useTheme"

const Button = Radium(({style, text, children, disabled, onClick}) => {
  const theme = useTheme()
  const defaultHandler = () => console.log("clicked")

  return (
    <FlexBox hCenter style={[theme.buttonStyle,
      disabled && {cursor: "not-allowed"},
      style]} onClick={(!disabled && onClick) || defaultHandler}>
      {text || children}
    </FlexBox>
  )
})

export default Button
