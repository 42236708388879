import {useState} from "react"
import axios from "axios"
import DisplayCouldNotLoadMessage from "pages/DisplayCouldNotLoadMessage"
import AppContextProvider from "api/app/AppContext"
import {StyleRoot} from "radium"
import Bootstrapper from "Bootstrapper"
import useOnmount from "hooks/useOnMount"

function useConfigLoader() {
  const [loading, setLoading] = useState(true)
  const [config, setConfig] = useState(undefined)
  const [error, setError] = useState(undefined)

  useOnmount(() => {
    axios.get(
      `${window.location.origin}${process.env.PUBLIC_URL}/api/key`,
    ).then(res => {
      setConfig(res.data)
    }).catch(error => {
      setError(error)
    }).finally(() => setLoading(false))
  })

  return [loading, config, error]
}

export default function ConfigLoader() {
  const [loading, config, error] = useConfigLoader()

  if (loading) {
    return null
  } else if (error) {
    return <DisplayCouldNotLoadMessage/>
  } else {
    return (
      <AppContextProvider config={config}>
        <StyleRoot>
          <Bootstrapper/>
        </StyleRoot>
      </AppContextProvider>
    )
  }
}