import {useRef, useCallback, useState} from "react";
import {merge, BehaviorSubject} from "rxjs";
import {tap, filter, withLatestFrom} from "rxjs/operators";
import useOnMount from "./useOnMount";

export const useStreamReducer = (reduce, initialState, epics, config) => {
  const epicStreamRef = useRef(new BehaviorSubject({action: undefined, prevState: {}, currState: initialState}));
  const actionStreamRef = useRef(new BehaviorSubject({}));
  const stateStreamRef = useRef(new BehaviorSubject(initialState));
  const [ready, setReady] = useState(false);
  const [state, setState] = useState(initialState);

  const dispatch = useCallback((action) => {
    actionStreamRef.current.next(action)
  }, [actionStreamRef]);

  useOnMount(() => {
    const subscription = actionStreamRef.current.pipe(
      filter((action) => action.type),
      withLatestFrom(stateStreamRef.current),
      tap(([action, state]) => {
        const newState = reduce(state, action)
        stateStreamRef.current.next(newState)
        epicStreamRef.current.next({action, prevState: state, currState: newState})
      })
    ).subscribe()
    return () => subscription.unsubscribe()
  })

  useOnMount(() => {
    const subscription = stateStreamRef.current.subscribe(setState)
    return () => subscription.unsubscribe()
  })

  useOnMount(() => {
    const epic$ = epicStreamRef.current.pipe(filter(({action}) => action));
    const streams = epics.map(epic => epic(epic$, config));
    const subscription = merge(...streams)
      .pipe(filter(action => action.type))
      .subscribe(action => {
        dispatch(action);
      });
    setReady(true);
    return () => {
      subscription && subscription.unsubscribe();
    };
  });

  return [state, dispatch, ready]
};
