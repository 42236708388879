import Bowser from "bowser";
import * as Sentry from "@sentry/react"

// Initialize cache variables
let cachedDeviceInfo = null;
let previousUserAgent = '';

/**
 * Capitalizes the first letter of a string
 */
const capitalize = (str) => str ? str.charAt(0).toUpperCase() + str.slice(1) : '';

/**
 * Checks if a browser is running on iOS
 */
const isIOSBrowser = (userAgent, browserName) =>
  new RegExp(`${browserName}`, 'i').test(userAgent.toLowerCase());

/**
 * Retrieves and caches device information using Bowser
 */
export const getDeviceInfo = () => {
  try {
    const userAgent = navigator.userAgent;

    // Return cached info if userAgent hasn't changed
    if (cachedDeviceInfo && userAgent === previousUserAgent) {
      return cachedDeviceInfo;
    }

    previousUserAgent = userAgent;
    const parser = Bowser.getParser(userAgent);

    // Get basic info using Bowser
    const browser = parser.getBrowser();
    const os = parser.getOS();
    const platform = parser.getPlatform();
    const engine = parser.getEngine();

    // Simplify device type detection using Bowser's platform type
    const deviceType = capitalize(platform.type || 'desktop');

    // Determine core browser information
    const browserName = browser.name?.toLowerCase() || '';
    const browserInfo = {
      name: browser.name,
      version: browser.version,
      // Core browser detection
      isSafari: browserName === 'safari',
      isChrome: browserName === 'chrome',
      isFirefox: browserName === 'firefox',
      isEdge: browserName === 'edge',
      isOpera: browserName === 'opera',
      // iOS specific browser detection
      isCriOS: isIOSBrowser(userAgent, 'crios'),    // Chrome on iOS
      isFxiOS: isIOSBrowser(userAgent, 'fxios'),    // Firefox on iOS
      isEdgiOS: isIOSBrowser(userAgent, 'edgios'),  // Edge on iOS
    };

    // OS detection
    const osName = os.name || 'Unknown OS';
    const isAppleDevice = ['iOS', 'macOS'].includes(osName);

    cachedDeviceInfo = {
      userAgent,
      platform: platform.type,
      isMobile: platform.type === 'mobile',
      isTablet: platform.type === 'tablet',
      isDesktop: platform.type === 'desktop',
      language: navigator.language,
      browser: `${capitalize(browser.name || '')} ${browser.version || ''}`.trim(),
      browserInfo,
      os: osName,
      osVersion: os.version || 'Unknown',
      deviceType,
      engine: engine.name,
      isAppleDevice,
      isIOS: osName === 'iOS',
      isMacOS: osName === 'macOS',
      // Raw Bowser data
      bowser: { browser, os, platform, engine },
      // Feature detection
      features: {
        localStorage: (() => {
          try {
            localStorage.setItem('test', 'test');
            localStorage.removeItem('test');
            return true;
          } catch {
            return false;
          }
        })(),
        sessionStorage: (() => {
          try {
            sessionStorage.setItem('test', 'test');
            sessionStorage.removeItem('test');
            return true;
          } catch {
            return false;
          }
        })(),
        touchEnabled: 'ontouchstart' in window ||
          navigator.maxTouchPoints > 0 ||
          navigator.msMaxTouchPoints > 0
      }
    };

    return cachedDeviceInfo;
  } catch (error) {
    Sentry.captureMessage("Error getting device info: "+ error.message , "error")
    return {
      error: "Failed to retrieve device information",
      userAgent: navigator.userAgent,
      deviceType: "Desktop"
    };
  }
};