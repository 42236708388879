import produce from "immer"

export const actionTypes = {
  uploadPayslip: "uploadPayslip",
  uploadPayslipCompleted: "uploadPayslipCompleted",
  uploadPayslipFailed: "uploadPayslipFailed",
  resetPayslipState: "resetPayslipState",

  uploadBankStatement: "uploadBankStatement",
  uploadBankStatementCompleted: "uploadBankStatementCompleted",
  uploadBankStatementFailed: "uploadBankStatementFailed",
  resetBankStatementState: "resetBankStatementState",
}

export const actionFactory = {
  uploadPayslip: payload => ({type: actionTypes.uploadPayslip, payload}),
  uploadPayslipCompleted: () => ({type: actionTypes.uploadPayslipCompleted}),
  uploadPayslipFailed: payload => ({type: actionTypes.uploadPayslipFailed, payload}),
  resetPayslipState: () => ({type: actionTypes.resetPayslipState}),

  uploadBankStatement: payload => ({type: actionTypes.uploadBankStatement, payload}),
  uploadBankStatementCompleted: () => ({type: actionTypes.uploadBankStatementCompleted}),
  uploadBankStatementFailed: payload => ({type: actionTypes.uploadBankStatementFailed, payload}),
  resetBankStatementState: () => ({type: actionTypes.resetBankStatementState}),
}

export const incomeReducer = (state, action) => produce(state, draft => {
  const {type, payload} = action

  console.log("Reducing:", type)

  switch (type) {
    case actionTypes.uploadPayslip:
      draft.incomeState.uploadPayslipLoading = true
      break

    case actionTypes.uploadPayslipCompleted:
      draft.incomeState.uploadPayslipLoading = false
      draft.incomeState.uploadPayslipCompleted = true
      break

    case actionTypes.uploadPayslipFailed:
      draft.incomeState.uploadPayslipLoading = false
      draft.incomeState.uploadPayslipFailed = payload
      break

    case actionTypes.resetPayslipState:
      draft.incomeState.uploadPayslipLoading = false
      draft.incomeState.uploadPayslipCompleted = false
      draft.incomeState.uploadPayslipFailed = undefined
      break

    case actionTypes.uploadBankStatement:
      draft.incomeState.uploadBankStatementLoading = true
      break

    case actionTypes.uploadBankStatementCompleted:
      draft.incomeState.uploadBankStatementLoading = false
      draft.incomeState.uploadBankStatementFailed = undefined
      draft.incomeState.uploadBankStatementCompleted = true
      break

    case actionTypes.uploadBankStatementFailed:
      draft.incomeState.uploadBankStatementLoading = false
      draft.incomeState.uploadBankStatementFailed = payload
      draft.incomeState.uploadBankStatementCompleted = false
      break

    case actionTypes.resetBankStatementState:
      draft.incomeState.uploadBankStatementLoading = false
      draft.incomeState.uploadBankStatementCompleted = false
      draft.incomeState.uploadBankStatementFailed = undefined
      break

    default:
    // do nothing

  }
})

