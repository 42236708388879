import FlexBox from "components/layout/FlexBox"
import React from "react"
import {useTheme} from "hooks/useTheme"

export const MobileLayout = ({header, children, footer}) => {
  const {logo} = useTheme()
  return (
    <FlexBox style={{
      minHeight: "100vh",
    }} column>
      <FlexBox centered>
        <FlexBox spaceBetween style={{paddingLeft: 40, paddingRight: 40}}>
          <FlexBox style={{minWidth: 300, maxHeight: 100}} margin centered>
            <img src={logo} height="100%" alt="SprintHive logo"/>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {header}
      <FlexBox style={{alignItems: 'flex-start'}} flexGrow>
        {children}
      </FlexBox>
      {footer}
    </FlexBox>
  )
}

