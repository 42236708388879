import produce from "immer"

export const actionTypes = {
  uploadSecureCaptureSelfie: "uploadSecureCaptureSelfie",
  uploadSecureCaptureSelfieCompleted: "uploadSecureCaptureSelfieCompleted",
  uploadSecureCaptureSelfieFailed: "uploadSecureCaptureSelfieFailed",
  onTrySecureCaptureSelfieAgainClicked: "onTrySecureCaptureSelfieAgainClicked",
  onFaceDetectionTextChanged: "onFaceDetectionTextChanged",
}

export const actionFactory = {
  uploadSecureCaptureSelfie: payload => ({type: actionTypes.uploadSecureCaptureSelfie, payload}),
  uploadSecureCaptureSelfieCompleted: payload => ({type: actionTypes.uploadSecureCaptureSelfieCompleted, payload}),
  uploadSecureCaptureSelfieFailed: payload => ({type: actionTypes.uploadSecureCaptureSelfieFailed, payload}),
  onTrySecureCaptureSelfieAgainClicked: () => ({type: actionTypes.onTrySecureCaptureSelfieAgainClicked}),
  onFaceDetectionTextChanged: payload => ({type: actionTypes.onFaceDetectionTextChanged, payload}),
}

export const identityReducer = (state, action) => produce(state, draft => {
  const {type, payload} = action

  console.log("Reducing:", type)

  switch (type) {
    case actionTypes.uploadSecureCaptureSelfie:
      draft.identityState.uploadSecureCaptureSelfieInProgress = true
      draft.identityState.uploadSecureCaptureSelfieFailed = undefined
      break

    case actionTypes.uploadSecureCaptureSelfieCompleted:
      draft.identityState.uploadSecureCaptureSelfieCompleted = true
      draft.identityState.uploadSecureCaptureSelfieFailed = undefined
      draft.identityState.uploadSecureCaptureSelfieInProgress = false
      break

    case actionTypes.uploadSecureCaptureSelfieFailed:
      draft.identityState.uploadSecureCaptureSelfieCompleted = undefined
      draft.identityState.uploadSecureCaptureSelfieFailed = payload
      draft.identityState.uploadSecureCaptureSelfieInProgress = false
      break

    case actionTypes.onTrySecureCaptureSelfieAgainClicked:
      draft.identityState.uploadSecureCaptureSelfieCompleted = undefined
      draft.identityState.uploadSecureCaptureSelfieFailed = undefined
      draft.identityState.faceDetection = undefined
      break

    case actionTypes.onFaceDetectionTextChanged:
      if (!draft.identityState.faceDetection) {
        draft.identityState.faceDetection = {}
      }
      payload.faceDetectionCodes.forEach(errorCode => {
        draft.identityState.faceDetection[errorCode] =
          draft.identityState.faceDetection[errorCode] !== undefined
            ? draft.identityState.faceDetection[errorCode] + 1
            : 1
      })
      break

    default:
    // do nothing for now
  }
})

